<template>
  <div class="surface-card p-4 shadow-2 border-round">
    <div class="font-medium text-3xl text-900 mb-3">Perfil</div>
    <div class="text-500 mb-5">Faça o agendamento do seu paciente.</div>
    <div class="surface-card p-4 shadow-2 border-round p-fluid mb-3">
      <div class="p-fluid grid mb-5">
        <div class="col-12 justify-content-center align-items-center flex">
          <Avatar v-if="user.urlImage" size="large" shape="circle" style="width: 10rem; height: 10rem; object-fit: cover" class="mr-2" :image="user.urlImage"></Avatar>
          <Avatar icon="pi pi-user" v-if="!user.urlImage" class="mr-2" size="xlarge" style="height: 10rem; width: 10rem;background-color:#2196F3; color: #ffffff" shape="circle"/><br>
        </div>
        <div class="col-12 justify-content-center align-items-center flex">
          <FileUpload id="avatar2" auto mode="basic" name="avatar" :custom-upload="true" @select="onEnviar($event)" @uploader="onSendFirebase" accept="image/*" :maxFileSize="5000000" class="p-button-outlined p-button-plain" chooseLabel="Enviar Imagem"></FileUpload>
        </div>
      </div>
      <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12">
          <label for="nickname2" >Nome</label>
          <InputText id="nickname2" v-model="user.name" type="text" />
        </div>
        <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
        <div class="field mb-4 col-12 md:col-12">
          <label for="bio2" >Bio</label>
          <Textarea id="bio2" type="text" :rows="5" v-model="user.bio" :autoResize="true"></Textarea>
        </div>
        <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="email2" >E-mail</label>
          <InputText id="email2" type="email" disabled v-model="user.email"/>
        </div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="country2" >Celular</label>
          <InputMask mask="(99) 99999-9999" id="city2" v-model="user.celular"/>
        </div>
        <div class="field mb-4 col-12 md:col-4">
          <label for="country2" >CPF</label>
          <InputMask id="city2"  mask="999.999.999-99" v-model="user.cpf"/>
        </div>
        <div class="field mb-4 col-12 md:col-4">
          <label for="city2" >RG/Conselho</label>
          <InputText id="city2" type="text" v-model="user.rg"/>
        </div>
        <div class="field mb-4 col-12 md:col-4">
          <label for="state2" >Orgão/Estado</label>
          <InputText id="state2" type="text" v-model="user.orgaoEstado"/>
        </div>
        <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
        <div class="field mb-4 col-12">
          <label for="website2" >Endereço</label>
          <div class="p-inputgroup">
            <InputText id="website2" type="text" v-model="user.endereco"/>
          </div>
        </div>
      </div>
    </div>
    <Toolbar class="mb-2">
      <template v-slot:end>
        <div class="my-2">
          <Button label="Cancelar" icon="pi" class="p-button-outlined mr-2" @click="$router.back()"/>
          <Button label="Salvar" icon="pi pi-save" class="p-button-success" @click="save"/>
        </div>
      </template>
    </Toolbar>
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {email, required} from "@vuelidate/validators";
import UserService from "@/service/UserService";
import firebase from "firebase/compat";

export default {
  name: "profile-user",
  setup: () => ({
    v$: useVuelidate()
  }),
  validations () {
    return {
      user: {
        firstName: {required},
        lastName: {required},
        email: {required, email}
      }
    }
  },
  created() {
    this.userService = new UserService();
  },
  data() {
    return {
      userService: null,
      user: {},
      authoritiesList: ['ADMIN', 'USER'],
      authoritiesSelected: null,
      submitted: false,
      confirmPassword: null
    }
  },
  async mounted() {
    const loader = this.$loading.show();
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await this.userService.findById(user.id);
    if (response.status === 200) {
      this.user = response.data;
    }
    loader.hide();
  },
  methods: {
    async save() {
      await this.userService.save(this.user)
      this.$toast.add({severity:'success', summary: 'Sucesso', detail:'Perfil atualizado com sucesso.', life: 3000});
    },
    clearForm() {
      this.user = {};

    },

    onEnviar(event){
      this.imageData = event.files[0];
      console.info(`IMAGE: ${JSON.stringify(this.imageData)}`);
    },

    onSendFirebase() {
      const loader = this.$loading.show();
      const fileName = this.user.id + '.jpg';
      const storageRef = firebase.storage().ref(`clinical/profile/${this.user.id}/${fileName}`).put(this.imageData);
      storageRef.on(`state_changed`,snapshot=>{
            this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          }, error=>{
            console.log(error.message);
            loader.hide();
          },
          ()=>{this.uploadValue=100;
            storageRef.snapshot.ref.getDownloadURL().then((url)=>{
              this.user.urlImage =url;
              console.info(`URL: ${this.user.urlImage}`);
              loader.hide();
            });
          }
      );
    },
  }
}
</script>

<style scoped>

</style>
